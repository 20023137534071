import axios from "axios";
import { getAuth } from "firebase/auth";
import { Application } from "../types/CoreTypes";
import Constants from "./Constants";

interface IApplicationService {
    getAll: () => Promise<any>;
    updateStatus: (id: number, status: string) => Promise<any>;
    updateNotes: (id: number, notes: string) => Promise<any>;
}

class ApplicationService implements IApplicationService {
    getAll = async () => {
        const auth = getAuth();
        let idToken = await auth.currentUser?.getIdToken(true);
    
        let url = `${Constants.apiBaseUrl}/userApplication/all`;
        try {
            if (!idToken) throw new Error("invalid token");

            let res = await axios({
                url, 
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`
                }
            });

            return { success: true, applications: res.data.applications as Application };
        } catch (err) {
            //could alert there was error or that they are in offline mode
            return { success: false };
        }
    };

    updateNotes = async (id: number, notes: string) => {
        if (!id && !notes) {
            return { success: false }
        }
        const auth = getAuth();
        let idToken = await auth.currentUser?.getIdToken(true);
    
        try {
            if (!idToken) throw new Error("invalid token");
            let res = await axios({
                url: `${Constants.apiBaseUrl}/userApplication`,
                method: 'PATCH',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`
                },
                data: { id, notes }
            });
            return { success: true };
        } catch (err) {
            return { success: false };
        }
    }

    updateStatus = async (id: number, status: string) => {
        if (!id && !status) {
            return { success: false }
        }
        const auth = getAuth();
        let idToken = await auth.currentUser?.getIdToken(true);
    
        try {
            if (!idToken) throw new Error("invalid token");
            let res = await axios({
                url: `${Constants.apiBaseUrl}/userApplication`,
                method: 'PATCH',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`
                },
                data: { id, status }
            });
            return { success: true };
        } catch (err) {
            return { success: false };
        }
    }
}

export default new ApplicationService() as IApplicationService;